<template>
  <div>
    <Header></Header>
    <Column></Column>
    <div class="layui-container">
      <div class="layui-row layui-col-space15">
        <div class="layui-col-md8">
          <div class="fly-panel">
            <div class="fly-panel-title fly-filter">
              <a>置顶</a>
            </div>
            <ul class="fly-list">

              <li v-for="comment in stickyComments"
                  :key="comment.id">
                <a href="" class="fly-avatar">
                  <img v-if="comment.publish_user.image" :src="comment.publish_user.image.url"
                       :alt="comment.publish_user.name">
                </a>
                <h2>
                  <a class="layui-badge">{{ comment.column.name }}</a>
                  <a href="javascript:;" @click="goCommentDetail(comment.id)">{{ comment.title }}</a>
                </h2>
                <div class="fly-list-info">
                  <a href="" link>
                    <cite>{{ comment.publish_user.name }}</cite>
                    <!--                    <i class="iconfont icon-renzheng" title="认证信息：XXX"></i>-->
                    <i class="layui-badge fly-badge-vip">{{ comment.publish_user.role.name }}</i>
                  </a>
                  <span>{{ comment.publish_time }}</span>

                  <span class="fly-list-kiss layui-hide-xs" title="关注" v-if="comment.like_num"><i
                      class="iconfont icon-kiss"></i> {{ comment.like_num }}</span>
                  <span class="layui-badge fly-badge-accept layui-hide-xs" v-if="1 == comment.finish">已结</span>
                  <!--                  <span class="layui-badge fly-badge-accept layui-hide-xs">{{ 1 == comment.finish ? '已结' : '未结' }}</span>-->
                  <span class="fly-list-nums">
                    <i class="iconfont icon-pinglun1" title="回答"></i> {{ comment.comment_num || 0 }}
                  </span>
                </div>
                <div class="fly-list-badge">
                  <span class="layui-badge layui-bg-black" v-if="1 == comment.sticky">置顶</span>
                  <span class="layui-badge layui-bg-red" v-if="1 == comment.essence">精华</span>
                </div>
              </li>

            </ul>
          </div>

          <div class="fly-panel" style="margin-bottom: 0;">

            <div class="fly-panel-title fly-filter">
              <a href="javascript:;" :class="{'layui-this': cssVar.all}" @click="changeFinish('')">综合</a>
              <span class="fly-mid"></span>
              <a href="javascript:;" :class="{'layui-this': cssVar.unFinish}" @click="changeFinish('0')">未结</a>
              <span class="fly-mid"></span>
              <a href="javascript:;" :class="{'layui-this': cssVar.finish}" @click="changeFinish('1')">已结</a>
              <span class="fly-mid"></span>
              <a href="javascript:;" :class="{'layui-this': cssVar.essence}" @click="changeEssence('1')">精华</a>
              <span class="fly-filter-right layui-hide-xs">
                <a href="javascript:;" :class="{'layui-this': cssVar.new}" @click="changeSortType('new')">按最新</a>
                <span class="fly-mid"></span>
                <a href="javascript:;" :class="{'layui-this': cssVar.hot}" @click="changeSortType('hot')">按热议</a>
              </span>
            </div>

            <ul class="fly-list">

              <li v-for="comment in comments"
                  :key="comment.id">
                <a href="" class="fly-avatar">
                  <img v-if="comment.publish_user.image" :src="comment.publish_user.image.url"
                       :alt="comment.publish_user.name">
                </a>
                <h2>
                  <a class="layui-badge">{{ comment.column.name }}</a>
                  <a href="javascript:;" @click="goCommentDetail(comment.id)">{{ comment.title }}</a>
                </h2>
                <div class="fly-list-info">
                  <a href="" link>
                    <cite>{{ comment.publish_user.name }}</cite>
                    <!--                    <i class="iconfont icon-renzheng" title="认证信息：XXX"></i>-->
                    <i class="layui-badge fly-badge-vip">{{ comment.publish_user.role.name }}</i>
                  </a>
                  <span>{{ comment.publish_time }}</span>

                  <span class="fly-list-kiss layui-hide-xs" title="关注" v-if="comment.like_num"><i
                      class="iconfont icon-kiss"></i> {{ comment.like_num }}</span>
                  <span class="layui-badge fly-badge-accept layui-hide-xs" v-if="1 == comment.finish">已结</span>
                  <!--                  <span class="layui-badge fly-badge-accept layui-hide-xs">{{ 1 == comment.finish ? '已结' : '未结' }}</span>-->
                  <span class="fly-list-nums">
                    <i class="iconfont icon-pinglun1" title="回答"></i> {{ comment.comment_num || 0 }}
                  </span>
                </div>
                <div class="fly-list-badge">
                  <span class="layui-badge layui-bg-black" v-if="1 == comment.sticky">置顶</span>
                  <span class="layui-badge layui-bg-red" v-if="1 == comment.essence">精华</span>
                </div>
              </li>

            </ul>
            <div style="text-align: center">
              <div class="laypage-main">
                <a href="case/index" class="laypage-next">更多案例</a>
              </div>
            </div>

          </div>
        </div>
        <div class="layui-col-md4">

          <!--          <div class="fly-panel">-->
          <!--            <h3 class="fly-panel-title">最新资料</h3>-->
          <!--            <ul class="fly-panel-main fly-list-static" v-if="newestComments.length > 0 ">-->
          <!--              <li v-for="comment in newestComments" :key="comment.id">-->
          <!--                <a href="javascript:;" target="_blank" @click="goCommentDetail(comment.id)">{{comment.title}}</a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--            &lt;!&ndash; 无数据时 &ndash;&gt;-->
          <!--            <div v-else style="margin-top: 50px; text-align: center; margin-bottom: 20px; height: 70px; color: #999;">没有相关数据</div>-->
          <!--          </div>-->


          <dl class="fly-panel fly-list-one">
            <dt class="fly-panel-title">热议案例</dt>
            <div v-if="hotComments.length > 0">
              <dd v-for="comment in hotComments" :key="comment.id">
                <a href="javascript:;" @click="goCommentDetail(comment.id)">{{ comment.title }}</a>
                <span><i class="iconfont icon-pinglun1"></i> {{ comment.comment_num || 0 }}</span>
              </dd>
            </div>
            <!-- 无数据时 -->
            <div v-else class="fly-none">没有相关数据</div>
          </dl>

<!--          <div class="fly-panel">-->
<!--            <div class="fly-panel-title">-->
<!--              官方出品-->
<!--            </div>-->
<!--            <div class="fly-panel-main">-->
<!--              <a href="http://tf.ynatd.com" target="_blank" class="fly-zanzhu"-->
<!--                 time-limit="2021.03.07-2021.03.08"-->
<!--                 style="background-color: #20222A; background-image: linear-gradient(to right,#20222A,#3E4251);">嘉奇教育ATD多元智能天赋测评系统</a>-->
<!--            </div>-->
<!--            <div class="fly-panel-main">-->
<!--              <a href="http://www.ynatd.com" target="_blank" class="fly-zanzhu"-->
<!--                 time-limit="2021.03.07-2021.03.08"-->
<!--                 style="background-color: #009688; background-image: linear-gradient(to right,#009688,#5FB878);">嘉奇教育官网</a>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="fly-panel">-->
<!--            <div class="fly-panel-title">-->
<!--              官方推荐-->
<!--            </div>-->
<!--            <div class="fly-panel-main">-->
<!--              <a href="https://tf.ynatd.com/img/recharge.7d4bfe13.png" target="_blank" class="fly-zanzhu"-->
<!--                 time-limit="2021.03.07-2021.03.08"-->
<!--                 style="background-color: #ff6a00; background-image: linear-gradient(to right,#ff6a00,#f39b5c);">现在缴费享受优惠</a>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="fly-panel" style="padding: 20px 0; text-align: center;">-->
<!--            <img src="@/assets/res/images/weixin.jpg" style="max-width: 100%;" alt="layui">-->
<!--            <p style="position: relative; color: #666;">微信扫码关注 嘉奇教育 公众号</p>-->
<!--          </div>-->

<!--          <div class="fly-panel fly-link">-->
<!--            <h3 class="fly-panel-title">友情链接</h3>-->
<!--            <dl class="fly-panel-main">-->
<!--              <dd><a href="http://www.ynatd.com/" target="_blank">嘉奇教育</a></dd>-->
<!--                                      <dd><a href="http://layim.layui.com/" target="_blank">WebIM</a></dd>-->
<!--                                      <dd><a href="http://layer.layui.com/" target="_blank">layer</a></dd>-->
<!--                                      <dd><a href="http://www.layui.com/laydate/" target="_blank">layDate</a></dd>-->
<!--                                      <dd><a-->
<!--                                          href="mailto:xianxin@layui-inc.com?subject=%E7%94%B3%E8%AF%B7Fly%E7%A4%BE%E5%8C%BA%E5%8F%8B%E9%93%BE"-->
<!--                                          class="fly-link">申请友链</a></dd>-->
<!--            </dl>-->
<!--          </div>-->

        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Column from "@/components/Column";
import {getComment, getHotComment, getNewestComment, getStickyComment} from "@/assets/api/forum";
import global from "@/assets/const.define";

export default {
  name: 'Index',
  components: {
    Header,
    Footer,
    Column,
  },

  data() {
    return {
      stickyComments: [],
      comments: [],
      newestComments: [],
      hotComments: [],
      finish: '',
      essence: '',
      sortType: 'new',
      cssVar: {
        all: true,
        unFinish: false,
        finish: false,
        essence: false,
        new: true,
        hot: false
      }
    }
  },

  created() {
    this.initPage();
  },

  mounted() {
  },

  methods: {
    initPage() {
      this.getStickyComments(); //置顶贴
      this.getComments(); //所有贴
      this.getNewestComments(); //最新贴
      this.getHotComments(); //热议贴
    },
    getStickyComments() {
      let that = this;
      getStickyComment().then(res => {
        console.log('stickyComments: ', res.result)
        that.stickyComments = res.result;
      })
    },
    getComments() {
      let that = this;
      let queryParam = {
        page: 1,
        rows: 20,
        finish: that.finish,
        essence: that.essence,
        sortType: that.sortType,
      }
      getComment(queryParam).then(res => {
        console.log('comments: ', res.result)
        that.comments = res.result.data;
      })
    },
    changeFinish(val) {
      if (val == '') {
        this.cssVar.all = true;
        this.cssVar.unFinish = false;
        this.cssVar.finish = false;
        this.cssVar.essence = false
      } else if (val == '1') {
        this.cssVar.all = false;
        this.cssVar.unFinish = false;
        this.cssVar.finish = true;
        this.cssVar.essence = false
      } else if (val == '0') {
        this.cssVar.all = false;
        this.cssVar.unFinish = true;
        this.cssVar.finish = false;
        this.cssVar.essence = false
      }
      this.finish = val;
      this.essence = '';
      this.getComments();
    },
    changeEssence(val) {
      this.cssVar.all = false;
      this.cssVar.unFinish = false;
      this.cssVar.finish = false;
      this.cssVar.essence = true;
      this.essence = val;
      this.finish = '';
      this.getComments();
    },
    changeSortType(val) {
      if (val == 'new') {
        this.cssVar.new = true;
        this.cssVar.hot = false;
      } else if (val == 'hot') {
        this.cssVar.new = false;
        this.cssVar.hot = true;
      }
      this.sortType = val;
      this.getComments();
    },
    getNewestComments() {
      let that = this;
      getNewestComment({columnId: global.FORUM_TOP_COLUMN.DOC.id}).then(res => {
        console.log('newestComments: ', res.result)
        that.newestComments = res.result;
      })
    },
    getHotComments() {
      let that = this;
      getHotComment().then(res => {
        console.log('hotComments: ', res.result)
        that.hotComments = res.result;
      })
    },
    goCommentDetail(id) {
      this.$router.push({
        path: '/case/detail',
        query: {
          id: id
        }
      })
    },
  }
}

</script>

<style>

</style>
