<template>
  <div>
    <Header></Header>
    <Column></Column>

    <div class="layui-container">
      <div class="layui-row layui-col-space15">
        <div class="layui-col-md8">
          <div class="fly-panel" style="margin-bottom: 0;">

            <div class="fly-panel-title fly-filter">
              <a href="javascript:;" :class="{'layui-this': cssVar.all}" @click="changeFinish('')">综合</a>
              <span class="fly-mid"></span>
              <a href="javascript:;" :class="{'layui-this': cssVar.unFinish}" @click="changeFinish('0')">未结</a>
              <span class="fly-mid"></span>
              <a href="javascript:;" :class="{'layui-this': cssVar.finish}" @click="changeFinish('1')">已结</a>
              <span class="fly-mid"></span>
              <a href="javascript:;" :class="{'layui-this': cssVar.essence}" @click="changeEssence('1')">精华</a>
              <span class="fly-filter-right layui-hide-xs">
                <a href="javascript:;" :class="{'layui-this': cssVar.new}" @click="changeSortType('new')">按最新</a>
                <span class="fly-mid"></span>
                <a href="javascript:;" :class="{'layui-this': cssVar.hot}" @click="changeSortType('hot')">按热议</a>
              </span>
            </div>

            <ul class="fly-list" v-if="comments.length > 0 ">
              <li v-for="comment in comments"
                  :key="comment.id">
                <a href="javascript:;" class="fly-avatar">
                  <img v-if="comment.publish_user.image" :src="comment.publish_user.image.url"
                       :alt="comment.publish_user.name">
                </a>
                <h2>
                  <a class="layui-badge">{{ comment.column.name }}</a>
                  <a href="javascript:;" @click="goCommentDetail(comment.id)">{{ comment.title }}</a>
                </h2>
                <div class="fly-list-info">
                  <a href="javascript:;" link>
                    <cite>{{ comment.publish_user.name }}</cite>
                    <!--                    <i class="iconfont icon-renzheng" title="认证信息：XXX"></i>-->
                    <i class="layui-badge fly-badge-vip">{{ comment.publish_user.role.name }}</i>
                  </a>
                  <span>{{ comment.publish_time }}</span>

                  <span class="fly-list-kiss layui-hide-xs" title="关注" v-if="comment.like_num"><i
                      class="iconfont icon-kiss"></i> {{ comment.like_num }}</span>
                  <span class="layui-badge fly-badge-accept layui-hide-xs" v-if="1 == comment.finish">已结</span>
                  <!--                  <span class="layui-badge fly-badge-accept layui-hide-xs">{{ 1 == comment.finish ? '已结' : '未结' }}</span>-->
                  <span class="fly-list-nums">
                    <i class="iconfont icon-pinglun1" title="回答"></i> {{ comment.comment_num || 0 }}
                  </span>
                </div>
                <div class="fly-list-badge">
                  <span class="layui-badge layui-bg-black" v-if="1 == comment.sticky">置顶</span>
                  <span class="layui-badge layui-bg-red" v-if="1 == comment.essence">精华</span>
                </div>
              </li>
            </ul>

            <div v-else class="fly-none">没有相关数据</div>

            <div style="text-align: center; height: 60px; margin-top: 20px;">
              <!--              <div class="laypage-main">-->
              <!--                <span class="laypage-curr">1</span>-->
              <!--                <a href="/case/page/2/">2</a>-->
              <!--                <a href="/case/page/3/">3</a>-->
              <!--                <a href="/case/page/4/">4</a>-->
              <!--                <a href="/case/page/5/">5</a>-->
              <!--                <span>…</span>-->
              <!--                <a href="/case/page/148/" class="laypage-last" title="尾页">尾页</a>-->
              <!--                <a href="/case/page/2/" class="laypage-next">下一页</a>-->
              <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="paging.records"
                  :page-size="paging.page_size"
                  :current-page="paging.current_page"
                  :hide-on-single-page="hidden"
                  @current-change="changePage"
                  @prev-click="clickPrePage"
                  @next-click="clickNextPage"
              >
              </el-pagination>
              <!--              </div>-->
            </div>

          </div>
        </div>
        <div class="layui-col-md4">
          <dl class="fly-panel fly-list-one">
            <dt class="fly-panel-title">最新热议</dt>
            <div v-if="hotComments.length > 0">
              <dd v-for="comment in hotComments" :key="comment.id">
                <a href="javascript:;" @click="goCommentDetail(comment.id)">{{ comment.title }}</a>
                <span><i class="iconfont icon-pinglun1"></i> {{ comment.comment_num || 0 }}</span>
              </dd>
            </div>

            <!-- 无数据时 -->
            <div v-else class="fly-none">没有相关数据</div>
          </dl>

<!--          <div class="fly-panel">-->
<!--            <div class="fly-panel-title">-->
<!--              官方出品-->
<!--            </div>-->
<!--            <div class="fly-panel-main">-->
<!--              <a href="http://tf.ynatd.com" target="_blank" class="fly-zanzhu"-->
<!--                 time-limit="2017.09.25-2099.01.01"-->
<!--                 style="background-color: #20222A; background-image: linear-gradient(to right,#20222A,#3E4251);">嘉奇教育ATD多元智能天赋测评系统</a>-->
<!--            </div>-->
<!--            <div class="fly-panel-main">-->
<!--              <a href="http://www.ynatd.com" target="_blank" class="fly-zanzhu"-->
<!--                 time-limit="2017.09.25-2099.01.01"-->
<!--                 style="background-color: #009688; background-image: linear-gradient(to right,#009688,#5FB878);">嘉奇教育官网</a>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="fly-panel">-->
<!--            <div class="fly-panel-title">-->
<!--              官方推荐-->
<!--            </div>-->
<!--            <div class="fly-panel-main">-->
<!--              <a href="https://tf.ynatd.com/img/recharge.7d4bfe13.png" target="_blank" class="fly-zanzhu"-->
<!--                 time-limit="2017.09.25-2099.01.01"-->
<!--                 style="background-color: #ff6a00; background-image: linear-gradient(to right,#ff6a00,#f39b5c);">现在缴费立享优惠</a>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="fly-panel" style="padding: 20px 0; text-align: center;">-->
<!--            <img src="@/assets/res/images/weixin.jpg" style="max-width: 100%;" alt="layui">-->
<!--            <p style="position: relative; color: #666;">微信扫码关注 嘉奇教育 公众号</p>-->
<!--          </div>-->

        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Column from "@/components/Column";
import {getComment, getHotComment} from "@/assets/api/forum";

export default {
  name: 'CaseIndex',
  components: {
    Header,
    Footer,
    Column,
  },

  data() {
    return {
      comments: [],
      hotComments: [],
      columnId: '',
      finish: '',
      essence: '',
      sortType: 'new',
      page: 1,
      hidden: true,
      paging: {},
      cssVar: {
        all: true,
        unFinish: false,
        finish: false,
        essence: false,
        new: true,
        hot: false
      }
    }
  },

  watch: {
    $route: {
      handler(val, oldval) {
        this.columnId = this.$route.query.columnId;
        this.initPage();
      },
    }
  },

  created() {
    this.columnId = this.$route.query.columnId;
    this.initPage();
  },

  mounted() {
  },

  methods: {
    initPage() {
      this.getComments();
      this.getHotComments();
    },

    getComments() {
      let that = this;
      let queryParam = {
        page: that.page,
        rows: 20,
        finish: that.finish,
        essence: that.essence,
        sortType: that.sortType,
        columnId: that.columnId
      }
      getComment(queryParam).then(res => {
        console.log('columns: ', res.result);
        that.comments = res.result.data;
        that.paging = res.result.paging
      })
    },

    changePage(val) {
      this.page = val;
      this.getComments();
    },

    clickPrePage(val) {
      this.page = val;
      this.getComments();
    },

    clickNextPage(val) {
      this.page = val;
      this.getComments();
    },

    changeFinish(val) {
      if (val == '') {
        this.cssVar.all = true;
        this.cssVar.unFinish = false;
        this.cssVar.finish = false;
        this.cssVar.essence = false
      } else if (val == '1') {
        this.cssVar.all = false;
        this.cssVar.unFinish = false;
        this.cssVar.finish = true;
        this.cssVar.essence = false
      } else if (val == '0') {
        this.cssVar.all = false;
        this.cssVar.unFinish = true;
        this.cssVar.finish = false;
        this.cssVar.essence = false
      }
      this.finish = val;
      this.essence = '';
      this.getComments();
    },
    changeEssence(val) {
      this.cssVar.all = false;
      this.cssVar.unFinish = false;
      this.cssVar.finish = false;
      this.cssVar.essence = true;
      this.finish = '';
      this.essence = val;
      this.getComments();
    },
    changeSortType(val) {
      if (val == 'new') {
        this.cssVar.new = true;
        this.cssVar.hot = false;
      } else if (val == 'hot') {
        this.cssVar.new = false;
        this.cssVar.hot = true;
      }
      this.sortType = val;
      this.getComments();
    },

    getHotComments() {
      let that = this;
      getHotComment().then(res => {
        console.log('hotComments: ', res.result)
        that.hotComments = res.result;
      })
    },
    goCommentDetail(id) {
      this.$router.push({
        path: '/case/detail',
        query: {
          id: id
        }
      })
    },

  }
}
</script>
<style scoped>

</style>